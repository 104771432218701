@font-face {
  font-family: 'ScalaSansOT';
  src: url('ScalaSansOT-Regular.ttf');
  src: url('ScalaSansOT-Regular.woff2') format('woff2'),
    url('ScalaSansOT-Regular.woff') format('woff'),
    url('ScalaSansOT-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ScalaSansOT';
  src: url('ScalaSansOT-Light.ttf');
  src: url('ScalaSansOT-Light.woff2') format('woff2'),
    url('ScalaSansOT-Light.woff') format('woff'),
    url('ScalaSansOT-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
