@font-face {
	font-family: "pz-icon";
	src: url("./pz-icon.eot?18f93dfc5c4da5452ec6f2e8aefd5d77#iefix") format("embedded-opentype"),
url("./pz-icon.woff2?18f93dfc5c4da5452ec6f2e8aefd5d77") format("woff2"),
url("./pz-icon.woff?18f93dfc5c4da5452ec6f2e8aefd5d77") format("woff"),
url("./pz-icon.ttf?18f93dfc5c4da5452ec6f2e8aefd5d77") format("truetype"),
url("./pz-icon.svg?18f93dfc5c4da5452ec6f2e8aefd5d77#pz-icon") format("svg");
}

[class^="pz-icon-"],
[class*=" pz-icon-"],
.pz-icon-font {
	font-family: 'pz-icon' !important;
	font-style: normal;
	font-weight: normal !important;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.pz-icon-akinon:before {
  content: "\f101";
}
.pz-icon-arrow-left:before {
  content: "\f102";
}
.pz-icon-arrow-right:before {
  content: "\f103";
}
.pz-icon-arrow-up:before {
  content: "\f104";
}
.pz-icon-back:before {
  content: "\f105";
}
.pz-icon-bell:before {
  content: "\f106";
}
.pz-icon-blog:before {
  content: "\f107";
}
.pz-icon-body-care:before {
  content: "\f108";
}
.pz-icon-cart:before {
  content: "\f109";
}
.pz-icon-change:before {
  content: "\f10a";
}
.pz-icon-check:before {
  content: "\f10b";
}
.pz-icon-checkbox-off:before {
  content: "\f10c";
}
.pz-icon-checkbox-on:before {
  content: "\f10d";
}
.pz-icon-chevron-down:before {
  content: "\f10e";
}
.pz-icon-chevron-end:before {
  content: "\f10f";
}
.pz-icon-chevron-left:before {
  content: "\f110";
}
.pz-icon-chevron-right:before {
  content: "\f111";
}
.pz-icon-chevron-start:before {
  content: "\f112";
}
.pz-icon-chevron-up:before {
  content: "\f113";
}
.pz-icon-clock:before {
  content: "\f114";
}
.pz-icon-close:before {
  content: "\f115";
}
.pz-icon-copy:before {
  content: "\f116";
}
.pz-icon-cvc:before {
  content: "\f117";
}
.pz-icon-default:before {
  content: "\f118";
}
.pz-icon-directions:before {
  content: "\f119";
}
.pz-icon-discover:before {
  content: "\f11a";
}
.pz-icon-document:before {
  content: "\f11b";
}
.pz-icon-exclusive:before {
  content: "\f11c";
}
.pz-icon-exit:before {
  content: "\f11d";
}
.pz-icon-eye-off:before {
  content: "\f11e";
}
.pz-icon-eye-on:before {
  content: "\f11f";
}
.pz-icon-face-forest:before {
  content: "\f120";
}
.pz-icon-facebook-login:before {
  content: "\f121";
}
.pz-icon-facebook:before {
  content: "\f122";
}
.pz-icon-fav-off:before {
  content: "\f123";
}
.pz-icon-fb:before {
  content: "\f124";
}
.pz-icon-filter:before {
  content: "\f125";
}
.pz-icon-forward:before {
  content: "\f126";
}
.pz-icon-fresh:before {
  content: "\f127";
}
.pz-icon-gift-box:before {
  content: "\f128";
}
.pz-icon-gift:before {
  content: "\f129";
}
.pz-icon-giftbox:before {
  content: "\f12a";
}
.pz-icon-globe:before {
  content: "\f12b";
}
.pz-icon-google-login:before {
  content: "\f12c";
}
.pz-icon-google:before {
  content: "\f12d";
}
.pz-icon-hair-care:before {
  content: "\f12e";
}
.pz-icon-hamburger:before {
  content: "\f12f";
}
.pz-icon-heart-full:before {
  content: "\f130";
}
.pz-icon-heart-stroke:before {
  content: "\f131";
}
.pz-icon-info:before {
  content: "\f132";
}
.pz-icon-instagram:before {
  content: "\f133";
}
.pz-icon-layout-2:before {
  content: "\f134";
}
.pz-icon-layout-3:before {
  content: "\f135";
}
.pz-icon-linkedin:before {
  content: "\f136";
}
.pz-icon-logo-desktop:before {
  content: "\f137";
}
.pz-icon-logo-mobile:before {
  content: "\f138";
}
.pz-icon-logo:before {
  content: "\f139";
}
.pz-icon-mail:before {
  content: "\f13a";
}
.pz-icon-makeup:before {
  content: "\f13b";
}
.pz-icon-minus:before {
  content: "\f13c";
}
.pz-icon-mp-otp:before {
  content: "\f13d";
}
.pz-icon-pin:before {
  content: "\f13e";
}
.pz-icon-pinterest:before {
  content: "\f13f";
}
.pz-icon-plus:before {
  content: "\f140";
}
.pz-icon-radio-off:before {
  content: "\f141";
}
.pz-icon-radio-on:before {
  content: "\f142";
}
.pz-icon-search:before {
  content: "\f143";
}
.pz-icon-share:before {
  content: "\f144";
}
.pz-icon-soundarya-club:before {
  content: "\f145";
}
.pz-icon-spinner:before {
  content: "\f146";
}
.pz-icon-star:before {
  content: "\f147";
}
.pz-icon-store-pin:before {
  content: "\f148";
}
.pz-icon-stores:before {
  content: "\f149";
}
.pz-icon-tag:before {
  content: "\f14a";
}
.pz-icon-tick:before {
  content: "\f14b";
}
.pz-icon-tr-flag:before {
  content: "\f14c";
}
.pz-icon-track-order:before {
  content: "\f14d";
}
.pz-icon-trash:before {
  content: "\f14e";
}
.pz-icon-twitter:before {
  content: "\f14f";
}
.pz-icon-user:before {
  content: "\f150";
}
.pz-icon-warning:before {
  content: "\f151";
}
.pz-icon-wellness:before {
  content: "\f152";
}
.pz-icon-whatsapp:before {
  content: "\f153";
}
.pz-icon-youtube-play:before {
  content: "\f154";
}
.pz-icon-youtube:before {
  content: "\f155";
}
