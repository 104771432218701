@use '@akinon/next/assets/styles/' as pz-default;
@use './fonts/pz-icon.css' as fonts;

@import './fonts/Calibre/';
@import './fonts/Calluna/';
@import './fonts/Scala/';
@import './fonts/ClassGarmndBT/';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .header-grid-template-areas {
    grid-template-areas:
      'main-l main-m main-r'
      'nav nav nav';
  }

  .header-grid-area-main-l {
    grid-area: main-l;
  }

  .header-grid-area-main-m {
    grid-area: main-m;
  }

  .header-grid-area-main-r {
    grid-area: main-r;
  }

  .header-grid-area-nav {
    grid-area: nav;
  }

  .header-m-template-cols {
    grid-template-columns: auto 1fr auto;
  }

  html:has(.scroll-lock) {
    overflow-y: hidden;
    body {
      overflow-y: hidden;
    }
  }

  .input-date::-webkit-inner-spin-button,
  .input-date::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  @media (max-width: 480px) {
    .swiper-slide-active.custom-swiper-slide {
      width: 240px !important;
    }

    //added for mobile center slide
    .swiper-slide-active.mobile-center-slide {
      width: 260px !important;
    }
    //added for mobile center slide
  }

  .hidden-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-style: white-outline;
    -apple-pay-button-type: check-out;
  }
}

/* Config Swiper */
:root {
  --swiper-theme-color: #4d4d4d !important;
  --apple-pay-button-width: 250px;
  --apple-pay-button-height: 50px;
  --apple-pay-button-border-radius: 3px;
  --apple-pay-button-padding: 5px 0px;
  --apple-pay-button-box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #bec4c4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #585858;
}

/* center helper classes */
.absolute-center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.html-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 22.5px;
    font-family: 'Calluna';
    font-size: 24px;
    line-height: 33.6px;
  }

  p {
    margin-bottom: 22.5px;
  }
}
