@font-face {
  font-family: 'ClassGarmndBT';
  src: url('Classical-Garamond-Roman.woff2');
  src: url('Classical-Garamond-Roman.woff2') format('woff2'),
    url('Classical-Garamond-Roman.woff') format('woff'),
    url('Classical-Garamond-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ClassGarmndBT';
  src: url('Classical-Garamond-Bold.woff2');
  src: url('Classical-Garamond-Bold.woff2') format('woff2'),
    url('Classical-Garamond-Bold.woff') format('woff'),
    url('Classical-Garamond-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
